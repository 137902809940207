<!-- 进销存动表 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <!-- 仓库名称 -->
      <!-- {{ changeLoading }} -->
      <FormItem>
        <span>仓库名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.warehouse_id" clearable filterable class="iviewIptWidth250" @on-change="inventoryProductLookup">
          <Option v-for="(item, index) in storeList" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <!-- 供应商 -->
      <FormItem class="marginLeft40">
        <span>供应商名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.supplier_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in supplierSelect" :value="item.supplier_id" :key="index">{{ item.supplier_name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>产品编码：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model.trim="queryFrom.product_model_code" />
      </FormItem>
      <!-- 产品名称 -->
      <FormItem class="marginLeft40">
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_id" clearable filterable :disabled="!queryFrom.warehouse_id" class="iviewIptWidth250" @on-change="productChange">
          <Option v-for="(item, index) in inventoryProductLookupList" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>规格型号/SKU：</span>
        <Select class="iviewIptWidth250 marginRight60" multiple filterable clearable v-model="product_code_array" :max-tag-count="product_code_array.length == 1 ? 1 : 0">
          <Option v-for="(item, index) in modelList" :value="item.model_name" :key="index" :label="item.model_name">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 收货时间 -->
      <FormItem class="marginLeft40">
        <span>查询时间：</span>
        <DatePicker type="date" placeholder="请选择" class="iviewIptWidth250" clearable @on-change="changeTime($event, 1)" :options="options1"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" placeholder="请选择" class="iviewIptWidth250" :value="queryFrom.end_date" clearable :options="options" @on-change="changeTime($event, 2)"></DatePicker>
        <div class="swith marginLeft40">
          <span style="margin-right: 10px;">库存变动</span>
          <i-switch :true-value="1" :false-value="null" v-model="queryFrom.inventory_changes">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </div>
        <!-- <Checkbox size="default"  class="marginLeft40" :true-value="1" :false-value="null" v-model="queryFrom.inventory_changes">库存变动</Checkbox> -->
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="40">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
        <span class="pageBtn finger btnSure marginLeft20">
          <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Export">导出</span>
          <span v-if="Loagin" class="pageBtn finger btnSure">
            <Spin v-if="Loagin" style="display: inline-block; color: white;">
              <Icon type="ios-loading" class="demo-spin-icon-load"></Icon>
            </Spin>
          </span>
        </span>
        <span class="pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
        <!--        <span class="pageBtn finger btnSure marginLeft20" @click="test">测试</span>-->
      </FormItem>
    </Form>
    <div class="clearfix realeBox tabDiv" ref="pageTop">
      <!-- <Spin fix v-if="Loading"></Spin> -->
      <!-- <div class="spinBox" v-if="spinFlag">
        <Spin size="small" class="smallSpin">
          <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          <div>加载中...</div>
        </Spin>
      </div> -->
      <Table border show-summary :summary-method="handleSummary" :productList="listColumns" :option_page="option_page" :productData="listData" :height="tableHeight" :loading="Loading" :isLoad="Loading">
        <template slot-scope="{ row, index }" slot="do">
          <div @click="goDetails(row, index)"><span class="finger color389">详情</span></div>
        </template>
      </Table>
    </div>
    <div class="pageBtm clearfix">
      <div class="tabPages" v-if="listData.length > 0 && total">
        <Page :total="total" :current="queryFrom.page" @on-change="changePage" class="fl" />
        <div class="fr" style="font-size: 14px;">共计{{ total }}条记录</div>
      </div>
    </div>
    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'
import { downFile } from '@/service/http'
export default {
  name: 'purchaseAndSaleConsign',
  components: {
    Table,
    TableSetup,
  },
  data() {
    return {
      changeFlag: true,
      // 控制单独loading
      changeFlag1: false,
      changeFlag2: false,
      spinFlag: false,
      changePageFlag: false,
      Loading: false,
      supllyList: [],
      titleList: [],
      option_page: '29',
      // 动态表头弹窗
      setupStatus: false,
      // 表格数据
      listData: [],
      listColumns: [],
      // 表格头部标题
      listColumnsDisabled: [
        {
          title: '上期库存',
          align: 'center',
          width: 75,
          children: [
            {
              title: '数量',
              key: 'last_stock_inventory',
              align: 'center',
              width: 90,
            },
            {
              title: '成本金额',
              key: 'last_stock_cost',
              align: 'center',
              width: 150,
            },
          ],
        },
        {
          title: '本期入库',
          align: 'center',
          width: 75,
          children: [
            {
              title: '进货数量',
              key: 'purchase_inventory',
              align: 'center',
              width: 90,
            },
            {
              title: '进货成本',
              key: 'purchase_cost',
              align: 'center',
              width: 150,
            },
            {
              title: '调拨数量',
              key: 'allocate_inventory',
              align: 'center',
              width: 90,
            },
            {
              title: '调拨成本',
              key: 'allocate_cost',
              align: 'center',
              width: 150,
            },
            {
              title: '销售退货数量',
              key: 'sale_return_inventory',
              align: 'center',
              width: 120,
            },
            {
              title: '销售退货成本',
              key: 'sale_return_cost',
              align: 'center',
              width: 150,
            },
            {
              title: '入库合计数量',
              key: 'receipt_inventory',
              align: 'center',
              width: 120,
            },
            {
              title: '入库合计成本',
              key: 'receipt_cost',
              align: 'center',
              width: 150,
            },
          ],
        },
        {
          title: '本期出库',
          align: 'center',
          width: 75,
          children: [
            {
              title: '销售数量',
              key: 'sale_inventory',
              align: 'center',
              width: 90,
            },
            {
              title: '销售成本金额',
              key: 'sale_cost',
              align: 'center',
              width: 150,
            },
            {
              title: '销售金额',
              key: 'sale_amount',
              align: 'center',
              width: 150,
            },
            {
              title: '销售毛利',
              key: 'sale_gross',
              align: 'center',
              width: 150,
            },
            {
              title: '调拨数量',
              key: 'sale_allocate_inventory',
              align: 'center',
              width: 90,
            },
            {
              title: '调拨成本',
              key: 'sale_allocate_cost',
              align: 'center',
              width: 150,
            },
            {
              title: '采购退货出库数量',
              key: 'purchase_return_inventory',
              align: 'center',
              width: 140,
            },
            {
              title: '采购退货出库成本',
              key: 'purchase_return_cost',
              align: 'center',
              width: 150,
            },
            {
              title: '出库合计数量',
              key: 'delivery_inventory',
              align: 'center',
              width: 120,
            },
            {
              title: '出库合计成本',
              key: 'delivery_cost',
              align: 'center',
              width: 150,
            },
          ],
        },
        {
          title: '本期库存数量',
          key: 'current_stock_inventory',
          align: 'center',
          width: 120,
        },
        {
          title: '本期库存成本',
          key: 'current_stock_cost',
          align: 'center',
          width: 120,
        },
      ],
      storeList: [],
      supplierSelect: [],
      // 查询参数
      queryFrom: {
        warehouse_id: '',
        supplier_id: '',
        begin_date: '',
        end_date: '',
        page: 1,
        rows: 10,
        inventory_changes: null,
        product_model_code: null,
        model_name: null,
        product_name: null,
        product_id: '',
      },
      product_code_array: [],
      pages: {},
      total: 0, // 项目条数
      tableHeight: '120',
      // 时间设置
      options: {},
      Loagin: false,
      options1: {
        disabledDate(date) {
          return date && date.valueOf() > new Date() - 86400 * 1000
        },
      },
      current_stock_count: [], // 本期库存
      last_stock_count: [], // 上期库存
      storage_in_count: [], // 本期入库
      storage_out_count: [], // 本期出库
      // current_stock_count: [1113, 20884.95575221239], // 本期库存
      // last_stock_count: [0, 0], // 上期库存
      // storage_in_count: [0, 0, 0, 0, 0, 0, 0, 0], // 本期入库
      // storage_out_count: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 本期出库
      totalTable: ['last_stock_inventory', 'last_stock_cost', 'purchase_inventory', 'purchase_cost', 'allocate_inventory', 'allocate_cost', 'sale_return_inventory', 'sale_return_cost', 'receipt_inventory', 'receipt_cost', 'sale_inventory', 'sale_cost', 'sale_amount', 'sale_gross', 'sale_allocate_inventory', 'sale_allocate_cost', 'purchase_return_inventory', 'purchase_return_cost', 'delivery_inventory', 'delivery_cost', 'current_stock_inventory', 'current_stock_cost'],
      productList: [],
      modelList: [],
      inventoryProductLookupList: [],
    }
  },
  methods: {
    // 改变仓库名称
    inventoryProductLookup(e) {
      this.queryFrom.product_id = undefined
      this.queryFrom.product_name = null
      if (!e) return
      // 获取产品名称下拉
      this.$http.get(this.$apiConsign.inventoryProductLookup, { warehouse_id: this.queryFrom.warehouse_id }).then(res => {
        this.inventoryProductLookupList = res.data
      })
    },
    // 产品名称改变
    productChange(e) {
      if (!e) {
        this.queryFrom.product_name = null
        this.queryFrom.model_name = null
      }
      this.product_code_array = []
      // if (!e) return
      this.modelList = []
      this.queryProductCodeSelect(e)
    },
    // 获取供应商下指定产品下的规格型号
    queryProductCodeSelect(id) {
      this.$http.get(this.$apiConsign.acceptanceCodeSelect, { product_id: id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 根据仓库查sku
    queryItemnumber(subId) {
      this.$http.get(this.$apiConsign.inventoryCodeLookup, { product_id: subId }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 数字转金额处理
    formatMoney(totalNum) {
      // if (typeof totalNum === 'number') {
      //   return totalNum + '.00'
      // } else {
      //   return totalNum
      // }
      let money = 0
      if (totalNum >= 0) {
        let num = totalNum
        num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
        let valueArr = num.split('.')[0].split('').reverse()
        const valueFloat = num.split('.')[1]
        let valueString = ''
        for (let i = 0; i < valueArr.length; i++) {
          valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
        }
        money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
      } else {
        totalNum = Math.abs(totalNum)
        let num = totalNum
        num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
        let valueArr = num.split('.')[0].split('').reverse()
        const valueFloat = num.split('.')[1]
        let valueString = ''
        for (let i = 0; i < valueArr.length; i++) {
          valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
        }
        money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
        money = '-' + money
      }
      return money
    },
    // 合计行
    handleSummary({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计',
          }
          return
        }
        const values = data.map(item => item[key])
        // if (!values.every(value => isNaN(value))) {
        if (this.totalTable.includes(key)) {
          let v = 0
          // 上期库存
          if (key === 'last_stock_inventory') {
            // v = this.last_stock_count[0]
            v = this.last_stock_count.length > 0 ? this.last_stock_count[0] : '加载中...'
          }
          if (key === 'last_stock_cost') {
            // let money = this.formatMoney(this.last_stock_count[1])
            // v = '￥' + money
            v = this.last_stock_count.length > 0 ? '￥' + this.formatMoney(this.last_stock_count[1]) : '...'
            // this.last_stock_count.length > 0 ? ('￥' + '123'):'...'
          }
          // 本期入库
          if (key === 'purchase_inventory') {
            // v = this.storage_in_count[0]
            v = this.storage_in_count.length > 0 ? this.storage_in_count[0] : '...'
          }
          if (key === 'purchase_cost') {
            // let money = this.formatMoney(this.storage_in_count[1])
            // v = '￥' + money
            v = this.storage_in_count.length > 0 ? '￥' + this.formatMoney(this.storage_in_count[1]) : '...'
          }
          if (key === 'allocate_inventory') {
            // v = this.storage_in_count[2]
            v = this.storage_in_count.length > 0 ? this.storage_in_count[2] : '...'
          }
          if (key === 'allocate_cost') {
            // let money = this.formatMoney(this.storage_in_count[3])
            // v = '￥' + money
            v = this.storage_in_count.length > 0 ? '￥' + this.formatMoney(this.storage_in_count[3]) : '...'
          }
          if (key === 'sale_return_inventory') {
            // v = this.storage_in_count[4]
            v = this.storage_in_count.length > 0 ? this.storage_in_count[4] : '...'
          }
          if (key === 'sale_return_cost') {
            // let money = this.formatMoney(this.storage_in_count[5])
            // v = '￥' + money
            v = this.storage_in_count.length > 0 ? '￥' + this.formatMoney(this.storage_in_count[5]) : '...'
          }
          if (key === 'receipt_inventory') {
            // v = this.storage_in_count[6]
            v = this.storage_in_count.length > 0 ? this.storage_in_count[6] : '...'
          }
          if (key === 'receipt_cost') {
            // let money = this.formatMoney(this.storage_in_count[7])
            // v = '￥' + money
            v = this.storage_in_count.length > 0 ? '￥' + this.formatMoney(this.storage_in_count[7]) : '...'
          }
          // 本期出库
          if (key === 'sale_inventory') {
            // v = this.storage_out_count[0]
            v = this.storage_out_count.length > 0 ? this.storage_out_count[0] : '...'
          }
          if (key === 'sale_cost') {
            // let money = this.formatMoney(this.storage_out_count[1])
            // v = '￥' + money
            v = this.storage_out_count.length > 0 ? '￥' + this.formatMoney(this.storage_out_count[1]) : '...'
          }
          if (key === 'sale_amount') {
            // let money = this.formatMoney(this.storage_out_count[2])
            // v = '￥' + money
            v = this.storage_out_count.length > 0 ? '￥' + this.formatMoney(this.storage_out_count[2]) : '...'
          }
          if (key === 'sale_gross') {
            // let money = this.formatMoney(this.storage_out_count[3])
            // v = '￥' + money
            v = this.storage_out_count.length > 0 ? '￥' + this.formatMoney(this.storage_out_count[3]) : '...'
          }
          if (key === 'sale_allocate_inventory') {
            // v = this.storage_out_count[4]
            v = this.storage_out_count.length > 0 ? this.storage_out_count[4] : '...'
          }
          if (key === 'sale_allocate_cost') {
            // let money = this.formatMoney(this.storage_out_count[5])
            // v = '￥' + money
            v = this.storage_out_count.length > 0 ? '￥' + this.formatMoney(this.storage_out_count[5]) : '...'
          }
          if (key === 'purchase_return_inventory') {
            // v = this.storage_out_count[6]
            v = this.storage_out_count.length > 0 ? this.storage_out_count[6] : '...'
          }
          if (key === 'purchase_return_cost') {
            // let money = this.formatMoney(this.storage_out_count[7])
            // v = '￥' + money
            v = this.storage_out_count.length > 0 ? '￥' + this.formatMoney(this.storage_out_count[7]) : '...'
          }
          if (key === 'delivery_inventory') {
            // v = this.storage_out_count[8]
            v = this.storage_out_count.length > 0 ? this.storage_out_count[8] : '...'
          }
          if (key === 'delivery_cost') {
            // let money = this.formatMoney(this.storage_out_count[9])
            // v = '￥' + money
            v = this.storage_out_count.length > 0 ? '￥' + this.formatMoney(this.storage_out_count[9]) : '...'
          }
          // 本期库存数量
          if (key === 'current_stock_inventory') {
            // v = this.current_stock_count[0]
            v = this.current_stock_count.length > 0 ? this.current_stock_count[0] : '...'
          }
          if (key === 'current_stock_cost') {
            // let money = this.formatMoney(this.current_stock_count[1])
            // v = '￥' + money
            v = this.current_stock_count.length > 0 ? '￥' + this.formatMoney(this.current_stock_count[1]) : '...'
          }
          sums[key] = {
            key,
            value: v,
          }
        } else {
          if (index == 0) {
            sums[key] = {
              key,
              value: '合计',
            }
          } else {
            sums[key] = {
              key,
              value: '',
            }
          }
        }
      })
      return sums
    },
    // 导出
    Export() {
      if (this.Loagin) {
        return
      }
      if (!this.queryFrom.begin_date) {
        this.$Message.warning('请选择查询开始时间')
        return
      }
      if (!this.queryFrom.end_date) {
        this.$Message.warning('请选择查询结束时间')
        return
      }
      let data = {
        warehouse_id: this.queryFrom.warehouse_id,
        supplier_id: this.queryFrom.supplier_id,
        begin_date: this.queryFrom.begin_date,
        end_date: this.queryFrom.end_date,
        inventory_changes: this.queryFrom.inventory_changes,
      }
      this.Loagin = true
      this.$http.downFile(this.$apiConsign.getstatementstock_sell_storagelist, data, false).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '进销存表.xlsx'
        aLink.click()
        this.Loagin = false
      })
    },
    goDetails(item) {
      this.queryFrom.code_id = item.product_model_code
      this.$router.push({
        path: '/purchaseAndSaleFirstDetailConsign',
        query: {
          queryFrom: JSON.stringify(this.queryFrom),
        },
      })
    },
    // 分页改变
    changePage(e) {
      if (!this.queryFrom.begin_date) {
        this.$Message.warning('请选择查询开始时间')
        return
      }
      if (!this.queryFrom.end_date) {
        this.$Message.warning('请选择查询结束时间')
        return
      }
      this.queryFrom.page = e
      this.changePageFlag = false
      this.queryList()
    },
    // 查询仓库列表
    queryStoreList() {
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 900,
        is_consign:1
      }
      this.$http.get(this.$apiConsign.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    getSelect() {
      this.$http.get(this.$apiConsign.getSupplierSelect).then(res => {
        this.supplierSelect = res.data
        console.log(this.supplierSelect, 'shu')
      })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.begin_date = e
        that.queryFrom.end_date = ''
        that.options = {
          disabledDate(date) {
            return (date && date.valueOf() < new Date(that.queryFrom.begin_date).getTime() - 86400 * 1000) || (date && date.valueOf() > new Date())
          },
        }
      } else {
        this.queryFrom.end_date = e
      }
    },
    // 点击查询按钮
    query() {
      if (!this.queryFrom.begin_date) {
        this.$Message.warning('请选择查询开始时间')
        return
      }
      if (!this.queryFrom.end_date) {
        this.$Message.warning('请选择查询结束时间')
        return
      }
      // this.spinFlag = true
      // this.changeFlag2 = false
      // this.changeFlag1 = false
      this.changeFlag = true
      this.current_stock_count = []
      this.last_stock_count = []
      this.storage_in_count = []
      this.storage_out_count = []
      this.changeFlag1 = true
      this.queryFrom.page = 1
      this.changePageFlag = true
      this.queryList()
    },
    queryList() {
      // 进详情页面会带上code_id
      if (this.queryFrom.code_id) {
        delete this.queryFrom.code_id
      }
      this.Loading = true
      // 规格型号
      if (this.product_code_array.length != 0) {
        this.queryFrom.model_name = this.product_code_array ? this.product_code_array.join(',') : null
      }
      // 产品名称
      if (this.queryFrom.product_id) {
        let obj = this.inventoryProductLookupList.find(item => {
          return item.product_id == this.queryFrom.product_id
        })
        this.queryFrom.product_name = obj.product_name
      }
      // 一个列表一个合计,并且合计翻页时候不掉，查询一次只调用一次
      // 现在有两个接口调用，但是只需要一个spin
      if (this.changePageFlag) {
        let dataSumm = {
          begin_date: this.queryFrom.begin_date ? this.queryFrom.begin_date : null,
          end_date: this.queryFrom.end_date ? this.queryFrom.end_date : null,
          inventory_changes: this.queryFrom.inventory_changes ? this.queryFrom.inventory_changes : null,
          model_name: this.queryFrom.model_name ? this.queryFrom.model_name : null,
          product_id: this.queryFrom.product_id ? this.queryFrom.product_id : null,
          product_model_code: this.queryFrom.product_model_code ? this.queryFrom.product_model_code : null,
          product_name: this.queryFrom.product_name ? this.queryFrom.product_name : null,
          supplier_id: this.queryFrom.supplier_id ? this.queryFrom.supplier_id : null,
          warehouse_id: this.queryFrom.warehouse_id ? this.queryFrom.warehouse_id : null,
        }
        this.$http.get(this.$apiConsign.statementStockSellStorageListSumm, dataSumm, false).then(res => {
          if (res) {
            this.current_stock_count = res.current_stock_count
            this.last_stock_count = res.last_stock_count
            this.storage_in_count = res.storage_in_count
            this.storage_out_count = res.storage_out_count
            this.changeFlag1 = true
            // this.spinFlag = false
          }
        })
      }
      this.$http.get(this.$apiConsign.statementStockSellStorageList, this.queryFrom, false).then(res => {
        this.Loading = false
        this.changeFlag2 = true
        this.total = res.total
        this.listData = res.data
        // this.current_stock_count = res.current_stock_count
        // this.last_stock_count = res.last_stock_count
        // this.storage_in_count = res.storage_in_count
        // this.storage_out_count = res.storage_out_count
        for (let i = 0; i < this.listData.length; i++) {
          for (let v in this.listData[i].current_stock) {
            this.listData[i][v] = this.listData[i].current_stock[v]
          }
          for (let v in this.listData[i].last_stock) {
            this.listData[i][v] = this.listData[i].last_stock[v]
          }
          for (let v in this.listData[i].storage_in_info) {
            this.listData[i][v] = this.listData[i].storage_in_info[v]
          }
          for (let v in this.listData[i].storage_out_info) {
            this.listData[i][v] = this.listData[i].storage_out_info[v]
          }
        }
        this.listData.forEach((item, index) => {
          // this.totalPurchaseAmount = item.purchase_amount + this.totalPurchaseAmount
          // this.totalPurchaseQuantity = item.purchase_quantity + this.totalPurchaseQuantity
          // this.totalSaleQuantity = item.sale_quantity + this.totalSaleQuantity
          // this.totalSaleAmount = item.sale_amount + this.totalSaleAmount
          item.insert_time = item.insert_time ? this.$moment.unix(item.insert_time).format('YYYY-MM-DD') : ''
          item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : ''
          item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD') : ''
          item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD') : ''
          item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : ''
          item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : ''
          if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.business_license_valid_period = '长期'
          } else {
            item.business_license_valid_period = ''
          }
          if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.record_valid_period = '长期'
          } else {
            item.record_valid_period = ''
          }
          item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : ''
          item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : ''
          item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : ''
          item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : ''
          item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : ''
          item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : ''
          item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : ''
          item.purchase_amount = this.OpearateMoney(item.purchase_amount)
          for (let i in item) {
            if (i == 'last_stock_cost' || i == 'current_stock_cost' || i == 'purchase_cost' || i == 'allocate_cost' || i == 'sale_return_cost' || i == 'receipt_cost' || i == 'sale_cost' || i == 'sale_amount' || i == 'sale_gross' || i == 'sale_allocate_cost' || i == 'purchase_return_cost' || i == 'delivery_cost') {
              item[i] = this.OpearateMoney(item[i])
            }
          }
        })
        this.$nextTick(() => {
          // 页面渲染完成后的回调
          this.tableHeight = this.$refs.pageTop.offsetHeight - 50 + ''
        })
      })
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 处理金额
    OpearateMoney(item) {
      let num = item
      num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
      var valueArr = num.split('.')[0].split('').reverse()
      const valueFloat = num.split('.')[1]
      let valueString = ''
      for (let i = 0; i < valueArr.length; i++) {
        valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
      }
      const money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
      return '¥' + money
    },
    // 获取表格表头
    queryOption() {
      this.$http.get(this.$apiConsign.systemOptionItem, { option_page: '29' }).then(res => {
        this.titleList = res.data.enable
        localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
        for (let i = 0; i < this.titleList.length; i++) {
          if (this.titleList[i].show) {
            this.titleList[i].key = this.titleList[i].data_col
            this.titleList[i].width = this.titleList[i].column_width
            this.titleList[i].align = 'center'
            this.titleList[i].resizable = true
          }
        }
        this.listColumns = []
        this.listColumns.unshift({
          title: '序号',
          type: 'index',
          align: 'center',
          key: 'index',
          width: 60,
        })
        this.listColumns = [...this.listColumns, ...this.titleList, ...this.listColumnsDisabled]
        this.listColumns.push({
          title: '操作',
          align: 'center',
          slot: 'do',
          width: 75,
          fixed: 'right',
        })
        this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        console.log(this.listColumns)
      })
    },
  },
  created() {
    this.queryOption()
    this.getSelect()
    // this.queryProduct()
    this.queryStoreList()
    this.queryItemnumber()
  },
  computed: {
    // changeLoading() {
    //   if (!this.changeFlag1 && this.changeFlag2) {
    //     this.spinFlag = true
    //   } else {
    //     this.spinFlag = false
    //   }
    //   console.log(this.changeFlag1, this.changeFlag2, this.spinFlag, '123')
    // },
  },
}
</script>

<style scoped lang="less">
.pageBtm {
  height: 40px;
  .tabPages {
    margin-top: 10px;
    overflow: hidden;
    line-height: 32px;
  }
}
// 表格
.tabDiv {
  flex: 1;
  overflow: hidden;
  .totalFont {
    font-size: 14px;
  }
  .pages {
    margin-top: 10px;
    overflow: hidden;
    line-height: 32px;
  }
}
.pagePadding {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}
.paddingBot40px {
  padding-bottom: 40px;
}
.spinBox {
  position: absolute;
  top: 500px;
  left: 0;
  background-color: white;
  // opacity: 0.8;
  z-index: 999999;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}
.realeBox {
  position: relative;
}
.smallSpin {
  display: inline-block;
  // width: 200px;
  // height: 70px;
  transform: translateY(-43%);
  top: 50%;
  left: 50%;
  position: absolute;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  .itemTitle {
    width: 90px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  .itemTitle::after {
    content: '';
    display: inline-block;
    padding-left: 90%;
  }
}
// /deep/ .ivu-checkbox-checked .ivu-checkbox-inner:after {
//     content: '';
//     display: table;
//     width: 8px;
//     height: 16px;
//     position: absolute;
//     top: 5px;
//     left: 11px;
//     border: 2px solid #fff;
//     border-top: 0;
//     border-left: 0;
//     transform: rotate(
// 45deg
// ) scale(1);
//     transition: all .2s ease-in-out;
// }
// /deep/ .ivu-checkbox-inner:after{
//       content: '';
//     display: table;
//     width: 8px;
//     height: 32px;
//     position: absolute;
//     top:5px;
//     left: 11px;
//     border: 2px solid #fff;
//     border-top: 0;
//     border-left: 0;
//     transform: rotate(
// 45deg
// ) scale(0);
//     transition: all .2s ease-in-out;
// }
// /deep/ .ivu-checkbox-inner {
//     width: 32px;
//     height: 32px;
// }
// /deep/ .ivu-checkbox-checked .ivu-checkbox-inner:after {
//   width: 8px;
//   height: 16px;
//   top: 5px;
//   left: 10px;
// }
// /deep/ .ivu-checkbox-wrapper {
//   font-size: 16px;
// }
.swith {
  display: inline-block;
  height: 40px;
  // display: flex;
  align-items: center;
  line-height: 40px;
  font-size: 16px;
}
/deep/ .ivu-switch {
  width: 50px;
  height: 25px;
  line-height: 25px;
}
/deep/ .ivu-switch:after {
  width: 24px;
  height: 24px;
  top: 0px;
}
/deep/ .ivu-switch-checked .ivu-switch-inner {
  left: 7px;
}
/deep/ .ivu-switch-inner {
  left: 28px;
  font-size: 15px;
}
/deep/ .ivu-switch-checked:after {
  left: 25px;
}
</style>
